export default [
  {
    key: 'image',
    label: 'field.image',
    type: 'single-image',
    cols: 12,
    ph: 500,
    pw: 500,
    initKey: 'mediaFile',
    fullWidth: false,
    rules: 'required',
  },
  {
    key: 'sequenceOrder',
    label: 'field.sequenceOrder',
    type: 'text',
    cols: 12,
    rules: 'required|numeric',
  }
]
