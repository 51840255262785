<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <validation-observer ref="editForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        key="editForm"
        @submit="submit"
        :form="$refs.editForm"
        :disabled="invalid"
      >
        <b-row>
          <b-col
            cols="12"
            v-for="(banner, index) in data.banners"
            :key="`banner-${index}`"
          >
            <b-card>
              <b-row>
                <b-col cols="12" class="text-right">
                  <b-button
                    type="button"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="mt-1"
                    @click="remove(index)"
                    ><feather-icon style="cursor: pointer" icon="XIcon"
                  /></b-button>
                </b-col>
              </b-row>
              <n-input
                :key="`input-${index}-${key}}`"
                :fields="fields"
                v-model="data.banners[index]"
                :initValue="initData.banners[index]"
              ></n-input>
            </b-card>
          </b-col>
          <b-col cols="12" class="text-right">
            <b-button
              type="button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mt-1 mr-1"
              :loading="loading"
              @click="add()"
            >
              {{ $t("button.add") }}
            </b-button>
            <n-button-loading
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :loading="loading"
              :disabled="invalid"
              v-if="$can('update', 'store')"
            >
              {{ $t("button.saveChanges") }}
            </n-button-loading>
            <n-button-loading
              type="button"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="back"
            >
              {{ $t("button.back") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BTab,
  BTabs,
  BFormTextarea,
  BButton,
  BCardHeader,
  BCardBody,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import formInput from "./formInput";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

const BannerRepository = Repository.get("banner");

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    BTab,
    BTabs,
    BFormTextarea,
    BButton,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      key: 1,
      data: {
        banners: [
          {
            id: null,
            image: null,
            sequenceOrder: 1,
          },
        ],
      },
      initData: {
        banners: [],
      },
      loading: false,
      required,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      BannerRepository.index()
        .then((response) => {
          this.initData.banners = [...response.data.data];
          this.data.banners = [...response.data.data];
        })
        .catch()
        .then(() => {});
    },
    submit() {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          BannerRepository.update(this.data)
            .then((response) => {
              this.$toast.success(response.data.message);
              this.$router.push({ name: "list-banner" });
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.editForm.setErrors(error.response?.data?.message);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },

    add() {
      this.data.banners.push({
        id: null,
        image: null,
        sequenceOrder: 1,
      });
    },
    remove(index) {
      this.data.banners.splice(index, 1);
      this.key++;
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = [...formInput];

    return { fields };
  },
};
</script>
